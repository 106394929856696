import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Resumo from './components/Resumo'
import Comp from './components/Comp'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <Switch>
              <Route path="/" exact component={Resumo} />
              <Route path="/comp" exact component={Comp} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
