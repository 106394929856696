import React, { Component } from 'react';
import './Comp2.css'

class Comp2 extends Component {

    render() {
        return (
            <div className="fonteComp2">Comp 2</div>
        )
    }
}

export default Comp2;