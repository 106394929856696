import React, { Component } from 'react';
import './Comp1.css'

class Comp1 extends Component {

    render() {
        return (
            <div className="fonte">Comp 1</div>
        )
    }
}

export default Comp1;