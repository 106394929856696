import React, { Component } from 'react';

class Resumo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            valores: {
                valor_pagar_mes: 0.0,
                valor_pago_mes: 0.0,
                total_despesa_mes: 0.0,
                valor_receber_mes: 0.0,
                valor_recebido_mes: 0.0,
                total_receita_mes: 0.0,
                saldo_mes: 0.0,
                saldo_contas: 0.0
            }
        };
    }

    componentDidMount() {
        fetch("https://www.maroguimo.com.br/financeiroPessoal/rest/dashboard.php")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        valores: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                Resumo do Mês
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th className="text-right">Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ color: 'red', backgroundColor: "#f2d2d2" }}>
                                        <td>A Pagar</td>
                                        <td className="text-right">
                                            {this.state.valores.valor_pagar_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>
                                    <tr style={{ color: 'red', backgroundColor: "#f2d2d2" }}>
                                        <td>Pago</td>
                                        <td className="text-right">
                                            {this.state.valores.valor_pago_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>
                                    <tr style={{ color: '#fff', fontWeight: "bold", backgroundColor: "#f74a4a" }}>
                                        <td>Total</td>
                                        <td className="text-right">
                                            {this.state.valores.total_despesa_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2"></td>
                                    </tr>

                                    <tr style={{ color: 'green', backgroundColor: "#aae2ad" }}>
                                        <td>A Receber</td>
                                        <td className="text-right">
                                            {this.state.valores.valor_receber_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>
                                    <tr style={{ color: 'green', backgroundColor: "#aae2ad" }}>
                                        <td>Recebido</td>
                                        <td className="text-right">
                                            {this.state.valores.valor_recebido_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>
                                    <tr style={{ color: '#fff', fontWeight: "bold", backgroundColor: "#47894b" }}>
                                        <td>Total</td>
                                        <td className="text-right">
                                            {this.state.valores.total_receita_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2"></td>
                                    </tr>
                                    <tr style={{ fontWeight: "bold", color: (this.state.valores.saldo_mes > 0 ? "green" : "red") }}>
                                        <td>Saldo Previsto Mês</td>
                                        <td className="text-right">
                                            {this.state.valores.saldo_mes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Resumo;