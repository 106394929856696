import React, { Component } from 'react';
import Comp1 from './comp1/Comp1'
import Comp2 from './comp2/Comp2'

class Comp extends Component {

    render() {
        return (
            <div>
                <Comp1 />
                <Comp2 />
            </div>
        )
    }
}

export default Comp;